<template>
  <div>
    <div v-if="userManager.userData.app_metadata.canonical_id && !loading" class="list-group">
      <searchResultItems
        v-for="myTablesResult in myTablesResults"
        :key="myTablesResult.id"
        :cluster="myTablesResult.cluster"
        :database="myTablesResult.database"
        :schema="myTablesResult.schema"
        :tableName="myTablesResult.name"
        :description="myTablesResult.description"
        :keyValue="getKey(myTablesResult)"
        :owner="[`to-be-implemented`]"
        :isView="myTablesResult.is_view"
      />
      <div v-if="!myTablesResults.length && !errorMessage" class="alert alert-warning">
        <p>
          <b>Warning!</b>
        </p>
        <p>
          You have not been assigned as Data Steward for a table. Please contact Data Platform at
          <a
            href="mailto:PandaSquad@cimpress.com"
          >PandaSquad@cimpress.com</a> for assistance.
        </p>
      </div>
    </div>
    <div v-if="loading" align="center">
      <img src="spinner.svg" alt="loading">
    </div>
    <div v-if="!userManager.userData.app_metadata.canonical_id && !loading && !errorMessage" class="alert alert-warning">
      <p><b>Warning!</b></p>
      <p>
        You're not yet added to the data discovery platform. Please contact Data Platform at
        <a href="mailto:PandaSquad@cimpress.com">PandaSquad@cimpress.com</a> for assistance.
      </p>
    </div>
    <error-popup v-if="errorMessage"
                 errorType="alert-danger-dismissible"
                 :html="`${errorMessage}. Try refreshing or contact Data Platform Panda team at <a href='mailto:PandaSquad@cimpress.com'> PandaSquad@cimpress.com</a> for assistance.`"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorPopup from '../ErrorPopup.vue';
import searchResultItems from '../searchResultItems';

export default {
  name: 'ListGroup',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    searchResultItems,
    ErrorPopup
  },
  data() {
    return {
      myTablesResults: false,
      loading: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      myTables: state => state.users.myTables
    }),
    getKey() {
      return b => encodeURIComponent(`${b.database}://${b.cluster}.${b.schema}/${b.name}`);
    }
  },
  async created() {
    this.loading = true;
    this.errorMessage = null;
    try {
      await this.getOwnedTables({ platformClient: this.metadataPlatformClient, userEmail: this.userManager.userData.app_metadata.canonical_id });
      this.myTablesResults = this.myTables.table;
    } catch (e) {
      this.errorMessage = e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unexpected error occurred.';
      console.log(e);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getOwnedTables: 'users/getOwnedTables'
    })
  }
};
</script>
