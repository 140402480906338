<template>
  <div>
    <div class="col-md-1" />
    <div class="mainPages col-md-10">
      <MyTablesList />
    </div>
  </div>
</template>

<script>
import MyTablesList from '@/components/MyTables/MyTablesList';

export default {
  name: 'MyBookmarks',
  components: {
    MyTablesList
  }
};
</script>
